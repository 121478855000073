import React, { useContext } from "react";
import { BoxProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { getSliceAddress } from "common/get-address";
import {
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { ImEarth, ImUser } from "react-icons/im";
import { AuthContext } from "contexts/auth.context";
import { useAddressCopy } from "hooks/use-address-copy";
import { JAPANESE } from 'constant';

type LangChangerProps = {
  boxProps?: BoxProps;
};

export const MobileMenu: React.VFC<LangChangerProps> = ({ boxProps }) => {
  const { i18n } = useTranslation();
  const { value } = useContext(AuthContext);
  const { handleCopy } = useAddressCopy(value?.address!);

  const handleLanguage = (): void => {
    i18n.changeLanguage(i18n.language === 'ja' ? "en" : 'ja')
  };

  return (
    <ChakraMenu>
      <MenuButton
        as={IconButton}
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        <MenuItem icon={<ImEarth />} onClick={handleLanguage}>
          {i18n.language === JAPANESE ? "English" : "日本語" }
        </MenuItem>
        {value?.isLoggedIn && (
          <MenuItem icon={<ImUser />} onClick={() => handleCopy()}>
            {getSliceAddress(value?.address!)}
          </MenuItem>
        )}
      </MenuList>
    </ChakraMenu>
  );
};
