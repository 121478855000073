import Web3 from "web3";
import { AbiItem } from "web3-utils";
import artifact from "contracts/Airdrop.json";
import { Proof } from "interfaces";
import MewConnect from "@myetherwallet/mewconnect-web-client";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ENGLISH } from "constant";

const NETWORK = "mainnet"; // 'ropsten',

class Web3Connector {
  private _web3: Web3 | null = null;
  private _contract: any;
  public _provider: any;
  private _web3Modal: Web3Modal | undefined;
  public _address: string | null = null;

  async activate(language: string): Promise<void> {
    const providerOptions = Web3Connector.getProvider(language);
    try {
      this._web3Modal = new Web3Modal({
        network: NETWORK,
        cacheProvider: false,
        providerOptions,
      });
      this._provider = await this._web3Modal.connect();
      this._web3 = new Web3(this._provider);

      this._contract = new this._web3.eth.Contract(
        artifact.abi as AbiItem[],
        process.env.REACT_APP_CONTRACT_ADDRESS
      );

      this._address = await this.getAddress();
    } catch (error) {
      console.error(error);

      throw new Error();
    }
  }

  async getWeb3(): Promise<Web3> {
    return this._web3!;
  }

  async getAddress(): Promise<string> {
    const accounts = await this._web3!.eth.getAccounts();

    return accounts[0];
  }

  async claim(targetAddress: string, { amount, siblings }: Proof): Promise<void> {
    const address = await this.getAddress();
    const receipt = await this._contract.methods
      .claim(targetAddress, amount, siblings)
      .send({ from: address });

    return receipt;
  }

  async disconnect(): Promise<void> {
    if (this._provider?.close) {
      await this._provider.close();

      this._web3 = null;
      this._provider = null;
    }
  }

  static getProvider(language: string) {
    return {
      injected: {
        display: {
          description:
            language === ENGLISH
              ? "Connect to your MetaMask Wallet"
              : "MetaMask Walletに接続します。",
        },
        package: null,
      },
      mewconnect: {
        package: MewConnect,
        display: {
          description:
            language === ENGLISH
              ? "Scan with MEW wallet to connect"
              : "QRコードをスキャンしてMEW Walletに接続します。",
        },
        options: {
          rpc: process.env.REACT_APP_WEB3_RPC_URL,
          network: NETWORK,
        },
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID,
        },
      },
    };
  }
}

export default new Web3Connector();
