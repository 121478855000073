import React, { useContext} from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import Web3Connector from "providers/web3-connector";
import { AuthContext } from "contexts/auth.context";

export const Logout: React.VFC = () => {
  const { clear } = useContext(AuthContext)
  const { t } = useTranslation();
  const toast = useToast();

  const handleClick = async (): Promise<void> => {
    await Web3Connector.disconnect();
    clear();

    toast({
      title: t("Success Disconnect"),
      position: "top",
      status: "success",
      isClosable: true,
    });
  };

  return (
    <Button mt={5} colorScheme="facebook" variant="link" onClick={handleClick}>
      {t("Disconnect")}
    </Button>
  );
};
