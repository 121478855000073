import { ENGLISH, JAPANESE } from 'constant';

export const config = {
  language: {
    defaultLanguage: ENGLISH,
    languages: [ENGLISH, JAPANESE]
  },
  siteLink: 'https://worldcosplay.net/',
  socialLink: {
    facebook: "https://www.facebook.com/CureCosPlus",
    twitter: 'https://twitter.com/CureCosPlus"',
    instagram: "https://www.instagram.com/curecosplus/",
    weibo: "https://weibo.com/curecosplus",
  },
  textLink: {
    site: "https://curecos.com/en/",
    howToUse: {
      'jp': 'https://cot.curecos.com/docs/HowtoClaimJP.pdf',
      'en': 'https://cot.curecos.com/docs/HowtoClaimEN.pdf',
    },
    aboutUs: "https://corp.curecos.com/",
    contactUs: "https://curecos.com/en/contact",
    privacyPolicy: "https://curecos.com/en/privacy",
  },
};
