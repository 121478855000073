import React from "react";
import { Logo } from "components/atoms/logo";
import { FaFacebookF, FaTwitter, FaInstagram, FaWeibo } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import {
  ListItem,
  UnorderedList,
  Link,
  Box,
  Text,
  Flex,
  ListItemProps,
} from "@chakra-ui/react";
import { config } from "config";
import { JAPANESE, ENGLISH } from "constant";

const { socialLink, textLink } = config;

type FooterListItemProps = {
  component: () => JSX.Element;
  link: string;
  listProps: ListItemProps;
};

type FooterCompanyListItemProps = {
  text: string;
  link: string;
  listProps: ListItemProps;
};

const SnsListItem: React.VFC<FooterListItemProps> = ({
  link,
  component: Component,
  listProps,
}) => (
  <ListItem
    background="rgb(45, 45, 52)"
    borderRadius="50%"
    height="2rem"
    width="2rem"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...listProps}
  >
    <Link href={link} isExternal>
      <Component />
    </Link>
  </ListItem>
);

const CompanyListItem: React.VFC<FooterCompanyListItemProps> = ({
  text,
  link,
  listProps,
}) => (
  <ListItem {...listProps} mb={{ base: 2, md: 0 }}>
    <Link href={link} isExternal>
      <Text>{text}</Text>
    </Link>
  </ListItem>
);

const socialLists = [
  {
    link: socialLink.facebook,
    component: () => <FaFacebookF />,
    listProps: { mr: 4 },
  },
  {
    link: socialLink.twitter,
    component: () => <FaTwitter />,
    listProps: { mr: 4 },
  },
  {
    link: socialLink.instagram,
    component: () => <FaInstagram />,
    listProps: { mr: 4 },
  },
  {
    link: socialLink.weibo,
    component: () => <FaWeibo />,
    listProps: {},
  },
];

const companyLists = [
  {
    text: "Site",
    link: textLink.site,
    listProps: { mr: 6 },
  },
  {
    text: "使い方",
    link: textLink.howToUse.jp,
    listProps: { mr: 6 },
    filterLanguage: ENGLISH,
  },
  {
    text: "How to Use",
    link: textLink.howToUse.en,
    listProps: { mr: 6 },
    filterLanguage: JAPANESE,
  },
  {
    text: "About Us",
    link: textLink.aboutUs,
    listProps: { mr: 6 },
  },
  {
    text: "Contact Us",
    link: textLink.contactUs,
    listProps: { mr: 6 },
  },
  {
    text: "Privacy Policy",
    link: textLink.privacyPolicy,
    listProps: {},
  },
];

export const Footer: React.VFC = () => {
  const { i18n } = useTranslation();

  return (
    <Box
      as="footer"
      background="rgb(17, 17, 21)"
      color="white"
      bottom={0}
      width="100%"
    >
      <Box as="section" p="5rem 2rem">
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ md: "space-between" }}
          maxW="82rem"
          marginRight="auto"
          marginLeft="auto"
        >
          <Box>
            <Logo isOriginal={false} />
            <UnorderedList
              display="flex"
              listStyleType="none"
              marginInlineStart={0}
              mt={2}
            >
              {socialLists.map((social, index) => (
                <SnsListItem key={index} {...social} />
              ))}
            </UnorderedList>
          </Box>
          <Box>
            <UnorderedList
              display="flex"
              listStyleType="none"
              marginInlineStart={0}
              flexDirection={{ base: "column", md: "unset" }}
              textAlign={{ base: "left", md: "unset" }}
              mt={{ base: 4, md: 2 }}
            >
              {companyLists
                .filter((list) => i18n.language !== list.filterLanguage)
                .map((company, index) => (
                  <CompanyListItem key={index} {...company} />
                ))}
            </UnorderedList>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
