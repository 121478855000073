import React from "react";
import { Box, Container } from "@chakra-ui/react";

export const MainContainer: React.FC = ({ children }) => (
  <Box
    as="main"
    pt={{ base: "7rem", md: "10rem" }}
    pb="5rem"
    height="maxContent"
    flexGrow={1}
  >
    <Box width="100%">
      <Container
        maxWidth="872px"
        textAlign="center"
        marginLeft="auto"
        marginRight="auto"
      >
        {children}
      </Container>
    </Box>
  </Box>
);
