import { useContext } from "react";
import { AuthContext } from "contexts/auth.context";
import { useToast } from "@chakra-ui/react";
import Web3Connector from "providers/web3-connector";
import { useTranslation } from "react-i18next";

export const useLogin = (): {
  handleSignIn: () => Promise<void>;
} => {
  const { t, i18n } = useTranslation();
  const { save, clear } = useContext(AuthContext);
  const toast = useToast();

  const handleSignIn = async (): Promise<void> => {
    try {
      await Web3Connector.activate(i18n.language);

      Web3Connector._provider.on(
        "disconnect",
        async (error: { code: number; message: string }) => {
          clear();
        }
      );

      const address = Web3Connector._address!;

      save({ isLoggedIn: true, address });

      toast({
        title: t("Connecting Wallet"),
        position: "top",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      console.log(error);

      toast({
        title: t("Failed Wallet"),
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  };

  return { handleSignIn };
};
