import * as React from "react";
import { Box, Link } from "@chakra-ui/react";
import LogoImage from "assets/logo.svg";
import LogoWhiteImage from 'assets/logo-white.svg';
import { config } from 'config';

type LogoProps = {
  isOriginal?: boolean;
}

export const Logo: React.VFC<LogoProps> = ({ isOriginal = true }) => (
  <Box w="150px">
    <Link isExternal href={config.siteLink}>
      {isOriginal ? <img src={LogoImage} alt="logo" /> : <img src={LogoWhiteImage} alt="logoWhite" />}
    </Link>
  </Box>
);
