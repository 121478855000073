import React from "react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Logout } from "components/atoms/logout";

export const Complete: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text
        as="h4"
        color="rgb(45, 55, 72)"
        fontSize={{ base: "lg", md: "3xl" }}
        lineHeight="1.235"
        fontWeight={700}
        mb={3}
      >
        {t("Thank you Claim")}
      </Text>
      <Logout />
    </>
  );
};
