import React, { useContext, useEffect } from "react";
import { Flex, Text, Button, Input, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Logout } from "components/atoms/logout";
import { useForm, SubmitHandler } from "react-hook-form";
import { AuthContext } from "contexts/auth.context";
import web3 from "web3";

type FormProps = {
  handleClaim: (targetAddress: string) => Promise<void>;
  loading: boolean;
};

type Inputs = {
  targetAddress: string;
};

export const Form: React.VFC<FormProps> = ({ handleClaim, loading }) => {
  const { t } = useTranslation();
  const { value } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async ({
    targetAddress,
  }: Inputs): Promise<void> => await handleClaim(targetAddress);

  useEffect((): void => {
    if (value?.address) {
      setValue("targetAddress", value.address);
    }
  }, [value, setValue]);

  return (
    <>
      <Text
        as="h4"
        color="rgb(45, 55, 72)"
        fontSize={{ base: "lg", md: "3xl" }}
        lineHeight="1.235"
        fontWeight={700}
        mb={3}
      >
        {t("Claim Title")}
      </Text>
      <Text
        as="p"
        py="2"
        fontSize={{ base: "sm", md: "1.25rem" }}
        lineHeight="1.6"
        color="rgba(100, 110, 115, 0.7)"
        fontWeight={400}
      >
        {t("Claim Description")}
      </Text>
      <Flex
        as="form"
        alignItems="center"
        justifyContent="center"
        direction="column"
        onSubmit={handleSubmit(onSubmit)}
      >
        <VStack spacing={3} my={2} w="80%">
          <Text fontWeight="bold">{t("Target Address")}</Text>
          <Input
            type="text"
            defaultValue={value?.address}
            placeholder="0x....."
            {...register("targetAddress", {
              required: t("Target Address is required") as string,
              validate: (address: string) => {
                const isValid = web3.utils.isAddress(address);

                return isValid
                  ? isValid
                  : (t("Target Address format error") as string);
              },
            })}
          />
          {errors.targetAddress && (
            <Text color="red.500">{errors.targetAddress.message}</Text>
          )}
        </VStack>
        <Button
          type="submit"
          size="md"
          py={6}
          mt={4}
          w="100%"
          maxW="400px"
          color="white"
          background="#fa0079"
          isLoading={loading}
          isDisabled={loading}
          _hover={{ bg: "#ae0054" }}
          _focus={{ bg: "#ae0054" }}
          _active={{ bg: "#ae0054" }}
        >
          {t("Claim Button")}
        </Button>
        <Logout />
      </Flex>
    </>
  );
};
