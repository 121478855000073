import React from "react";
import { MainContainer } from "components/atoms/main-container";
import { useClaim } from "hooks/use-claim";
import { Complete } from "./complete";
import { Form } from "./form";

export const Claim: React.VFC = () => {
  const { handleClaim, loading, completed } = useClaim();

  return (
    <MainContainer>
      {completed ? (
        <Complete />
      ) : (
        <Form handleClaim={handleClaim} loading={loading} />
      )}
    </MainContainer>
  );
};
