import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nEn from './en/translation.json';
import i18nJa from './ja/translation.json';
import { getBrowserLanguage } from 'common/get-language';

const resources = {
  en: {
    translation: i18nEn,
  },
  ja: {
    translation: i18nJa
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getBrowserLanguage(),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;