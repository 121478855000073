import React from "react";
import { Flex, BoxProps, HStack, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ENGLISH, JAPANESE } from "constant";

type LangChangerProps = {
  boxProps?: BoxProps;
};

export const LangChanger: React.VFC<LangChangerProps> = ({ boxProps }) => {
  const { i18n } = useTranslation();

  return (
    <Flex {...boxProps}>
      <HStack spacing="4">
        <Button
          variant="link"
          color={i18n.language === ENGLISH ? "black" : "gray.500"}
          onClick={() => i18n.changeLanguage(ENGLISH)}
        >
          EN
        </Button>
        <Button
          variant="link"
          color={i18n.language === JAPANESE ? "black" : "gray.500"}
          onClick={() => i18n.changeLanguage(JAPANESE)}
        >
          日本語
        </Button>
      </HStack>
    </Flex>
  );
};
