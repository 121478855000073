import { Box, Text, Button, BoxProps } from "@chakra-ui/react";
import { AuthContext } from "contexts/auth.context";
import { useContext } from "react";
import { getSliceAddress } from 'common/get-address';
import { useAddressCopy } from 'hooks/use-address-copy';

type AccountProps = {
  boxProps?: BoxProps;
};

export const Account: React.VFC<AccountProps> = ({ boxProps }) => {
  const { value } = useContext(AuthContext);
  const { handleCopy } = useAddressCopy(value?.address!);

  return value?.isLoggedIn ? (
    <Box
      display="flex"
      alignItems="center"
      background="rgb(243, 244, 247)"
      borderRadius="xl"
      color="#061024"
      py="0"
      {...boxProps}
    >
      <Box px="3">
        <Text fontSize="md">Account</Text>
      </Box>
      <Button
        bg="rgb(237, 238, 242)"
        border="1px solid transparent"
        borderRadius="xl"
        m="1px"
        px={3}
        height="38px"
        variant="none"
        onClick={handleCopy}
      >
        <Text color="#061024" mr="2">
          {getSliceAddress(value?.address)}
        </Text>
      </Button>
    </Box>
  ) : null;
};
