// storage
export const USER_AUTH = "USER_AUTH";

// others
export const ENGLISH = 'en';
export const JAPANESE = "ja";

// Error
export const ERROR_ALREADY_CLAIMED = "already claimed";
export const ERROR_ADDRESS_NOT_FOUND = "Not Found your address";
