import React from "react";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { Logo } from "components/atoms/logo";
import { LangChanger } from "components/molecules/lang-changer";
import { MobileMenu } from 'components/organisms/mobile-menu';
import { Account } from "components/molecules/account";

export const Header: React.VFC = () => {
  const variant = useBreakpointValue({ md: "md" });

  return (
    <Flex
      as="header"
      background="white"
      boxShadow="rgb(140 152 164 / 25%) 0px 3px 6px 0px"
      py="1rem"
      alignItems="center"
    >
      <Flex
        w="100%"
        px={{ base: "2rem" }}
        marginLeft="auto"
        marginRight="auto"
        direction="row"
        alignItems="center"
        h="40px"
      >
        <Logo isOriginal />
        <Flex marginLeft="auto">
          {variant !== undefined ? (
            <>
              <Account />
              <LangChanger boxProps={{ px: { base: 0, md: "1rem" } }} />
            </>
          ) : (
            <MobileMenu boxProps={{ px: { base: 0, xl: "1rem" } }} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
