import { useToast } from "@chakra-ui/react";
import { getProof } from "common/get-proof";
import { useState, useCallback } from "react";
import { useTranslation, TFunction } from "react-i18next";
import Web3Connector from "providers/web3-connector";
import { ERROR_ADDRESS_NOT_FOUND, ERROR_ALREADY_CLAIMED } from "constant";

const getClaimErrorMsg = (
  t: TFunction<"translation", undefined>,
  msg: string
): string => {
  if (msg === ERROR_ADDRESS_NOT_FOUND) return t("Not found Claim");
  if (msg.includes(ERROR_ALREADY_CLAIMED)) return t("Already Claim");

  return t("Failure Claim");
};

export const useClaim = (): {
  handleClaim: (targetAddress: string) => Promise<void>;
  loading: boolean;
  completed: boolean;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const toast = useToast();
  const { t } = useTranslation();

  const handleClaim = useCallback(async (targetAddress: string): Promise<void> => {
    setLoading(true);

    try {
      const lowerTargetAddress = targetAddress.toLowerCase();

      const proof = await getProof(lowerTargetAddress);
      await Web3Connector.claim(lowerTargetAddress, proof);

      toast({
        title: t("Success Claim"),
        position: "top",
        duration: 9000,
        status: "success",
        isClosable: true,
      });

      setCompleted(true);
    } catch (error: any) {
      const message = getClaimErrorMsg(t, error.message);

      toast({
        title: message,
        position: "top",
        status: "error",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [t, toast]);

  return { handleClaim, loading, completed };
};
