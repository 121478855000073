import { useToast, useClipboard } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const useAddressCopy = (
  address: string
): {
  handleCopy: () => void;
} => {
  const toast = useToast();
  const { t } = useTranslation();
  const { onCopy } = useClipboard(address);

  const handleCopy = (): void => {
    onCopy();

    toast({
      title: t("Copy Address"),
      position: "top",
      status: "info",
      isClosable: true,
    });
  };

  return { handleCopy };
};
