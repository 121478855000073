import { createContext } from "react";
import { AuthInfo } from "interfaces";
import { useStorage } from "hooks/use-storage";
import { USER_AUTH } from "constant";

type AuthContextType = {
  value: AuthInfo | null;
  save: (input: AuthInfo) => void;
  clear: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  value: null,
  save: () => null,
  clear: () => null,
});

export const AuthProvider: React.FC = ({ children }) => {
  const { save, value, clear } = useStorage(USER_AUTH);

  return (
    <AuthContext.Provider value={{ save, value, clear }}>
      {children}
    </AuthContext.Provider>
  );
};
