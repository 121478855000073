import { useState, useEffect } from "react";
import { AuthInfo } from "interfaces";

export const useStorage = (
  key: string,
  isLocalStorage = false
): {
  value: AuthInfo | null;
  save: (input: AuthInfo) => void;
  clear: () => void;
} => {
  const getStorage = (): Storage => {
    return isLocalStorage ? localStorage : sessionStorage;
  };

  const getValue = () => {
    if (typeof window === "undefined") return null;

    const v = getStorage().getItem(key);
    if (v === null) return null;

    try {
      const parsed = JSON.parse(v);

      return parsed;
    } catch {
      return v;
    }
  };

  const [value, setValue] = useState<AuthInfo | null>(getValue());

  const save = (input: AuthInfo): void => {
    if (typeof input === "object" || typeof input === "string") {
      getStorage().setItem(
        key,
        typeof input === "object" ? JSON.stringify(input) : input
      );

      setValue(input);

      return;
    }

    throw new Error("passing value must be object or string");
  };

  const clear = (): void => {
    getStorage().removeItem(key);

    setValue(null);
  };

  useEffect((): void => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts: string[]): void => {
        if (accounts.length > 0) {
          setValue((preValue: AuthInfo | null) => {
            return {
              ...(preValue as AuthInfo),
              address: accounts[0],
            };
          });
        }
      });
    }
  }, [value]);

  return { value, save, clear };
};
