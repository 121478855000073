import { Proof } from "interfaces";
import { ERROR_ADDRESS_NOT_FOUND } from "constant";

const getHolders = async (): Promise<Record<string, Proof>> =>
  await fetch(`${process.env.REACT_APP_HOLDER_JSON_PATH}`).then((res) =>
    res.json()
  );

export const getProof = async (targetAddress: string): Promise<Proof> => {
  const holders = await getHolders();
  const target = holders[targetAddress];
  if (!target) {
    throw new Error(ERROR_ADDRESS_NOT_FOUND);
  }

  return {
    amount: target.amount,
    siblings: target.siblings,
  };
};
